@import '_component-library-imports.scss';
.system-alert-container {
  text-align: center;

  .system-alert-display {
    width: 100%;
    color: $usana-white-000;
    padding: 10px;
    border-color: black;
    display: inline-block;
  }

  .alert-level-info {
    background-color: $usana-blue-300;
  }

  .alert-level-danger {
    background-color: $usana-red-300;
  }

  .alert-level-warning {
    background-color: $usana-yellow-300;
  }

  .system-alert-close {
    font-size: 18px;
    cursor: pointer;
  }
}
