@import '_mixin-imports.scss';
@import '_component-library-imports.scss';
@import '@usana/ux/sass/style-params';

/* Overall font style */
.body-text-1,
.body-text-2,
.body-text-3,
h1,
h2,
h3,
h4,
h5,
h6,
.sub-heading-1,
.sub-heading-2,
.sub-heading-3,
.special-heading-1,
.special-heading-2,
.special-heading-3 {
  font-family: var(--default-font-family);
  color: $usana-grey-900;
  text-align: left;
}

/*  Body Text Sizes */
.body-text-1 {
  @include font-body;
}

.body-text-2 {
  @include font-body-2;
}

.body-text-3 {
  @include font-body-3;
}

/* Heading Text Sizes */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}
h1 {
  @include font-heading-1-mobile;

  @include media-breakpoint-up(md) {
    @include font-heading-1-desktop;
  }
}
h2 {
  @include font-heading-2-mobile;

  @include media-breakpoint-up(md) {
    @include font-heading-2-desktop;
  }
}
h3 {
  @include font-heading-3-mobile;

  @include media-breakpoint-up(md) {
    @include font-heading-3-desktop;
  }
}
h4 {
  @include font-heading-4;
}
h5 {
  @include font-heading-5;
}
h6 {
  @include font-heading-6;
}

/* Subheadings Text Sizes */
.sub-heading-1 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}
.sub-heading-2 {
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 700;
}
.sub-heading-3 {
  font-size: 14px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 700;
}

/* Special Heading Styles */
.special-heading-1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
  @include media-breakpoint-up(lg) {
    font-size: 64px;
    line-height: 80px;
  }
}
.special-heading-2 {
  font-size: 36px;
  line-height: 45px;
  font-weight: 700;
  @include media-breakpoint-up(lg) {
    font-size: 48px;
    line-height: 60px;
  }
}
.special-heading-3 {
  font-size: 28px;
  line-height: 35px;
  font-weight: 700;
}
