@import '_component-library-imports.scss';

/**** These code overrides iflat.css ****/
body#hub {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: $usana-grey-100;
  color: $usana-grey-800;
}

#hub .text-primary {
  color: $usana-blue-300;
}

#hub a.text-primary:hover {
  color: $usana-blue-300;
}

#hub .text-primary-dark {
  color: $usana-blue-300;
}

#hub a.text-primary-dark:hover {
  color: $usana-blue-300;
}

a {
  color: $usana-blue-400;
}

a:hover,
a:focus {
  color: $usana-blue-300;
  text-decoration: underline;
}

.modal-header > a .fontawesome-icons {
  color: $usana-grey-800;
  margin-top: 5px;
  font-size: 20px;
  vertical-align: middle;
  font-weight: 400;
  cursor: pointer;
}

.modal-header > a:hover .fontawesome-icons {
  color: $usana-grey-600;
}

.modal-loading-spinner-wrapper {
  height: 150px;
  position: relative;
}

.btn:active,
.btn.active {
  box-shadow: none !important;
}

.btn.btn-secondary {
  border: none;
}

.btn.btn-success {
  border: none;
}

.btn.btn-danger {
  border: none;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus {
  border: none;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus {
  border: none;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.btn.btn-success:hover,
.btn.btn-success:focus {
  border: none;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.btn.btn-primary:active {
  background: $usana-blue-400 !important;
  color: $usana-white-000 !important;
  border: none !important;
}

.btn.btn-secondary:active {
  border: none !important;
}

.btn.btn-danger:active {
  border: none !important;
}

.btn.btn-success:active {
  border: none !important;
}

#hub .pagination > li > a:hover,
#hub .pagination > li > a:focus,
#hub .pagination > li > span:hover,
#hub .pagination > li > span:focus {
  background-color: $usana-blue-300;
  border-color: $usana-blue-300;
  border-radius: 4px;
  color: $usana-white-000;
}

#hub .pagination > .active > a,
#hub .pagination > .active > a:hover,
#hub .pagination > .active > a:focus,
#hub .pagination > .active > span,
#hub .pagination > .active > span:hover,
#hub .pagination > .active > span:focus {
  background-color: $usana-blue-300;
  border-color: $usana-blue-300;
}

.modal-open::selection,
.main-hub-design::selection {
  background: $usana-blue-200;
}

#hub .form-control:focus {
  border: solid 1px $usana-blue-400;
}

#hub .btn-line-primary-dark {
  border: solid 1px $usana-blue-400;
  color: $usana-white-000;
}

#hub .btn-line-primary-dark-text {
  color: $usana-grey-800;
}

#content.hub-content {
  display: flex;
}

@font-face {
  font-family: 'entypo';
  src: url('/hub/fonts/entypo.eot?8644018');
  src:
    url('/hub/fonts/entypo.eot?8644018#iefix') format('embedded-opentype'),
    url('/hub/fonts/entypo.woff?8644018') format('woff'),
    url('/hub/fonts/entypo.ttf?8644018') format('truetype'),
    url('/hub/fonts/entypo.svg?8644018#entypo') format('svg');
  font-weight: normal;
  font-style: normal;
}

.full-width {
  width: 100%;
}

.btn.btn-plain {
  background-color: $usana-white-000;
}

.btn.btn-line-plain {
  background-color: $usana-white-000;
  border: solid 1px $usana-grey-200;
  padding: 0 20px;
}

.btn.btn-line-plain:hover {
  color: $usana-grey-900;
  background-color: $usana-white-000;
}

.btn.btn-line-plain:focus {
  margin-top: 0;
  color: $usana-grey-900;
}

.icon-success {
  color: $usana-green-300;
}

.icon-primary {
  color: $usana-blue-300;
}

.icon-secondary {
  color: $usana-grey-400;
}

.icon-danger {
  color: $usana-red-200;
}

.icon-disable {
  cursor: default;
  color: $usana-grey-200;
}

.text-bold {
  font-weight: bold;
}

.loading-spinner {
  width: 80px;
  height: 80px;
}

select[multiple].form-control {
  height: auto !important;
}

.form-group-small .form-control {
  height: 32px !important;
  padding: 5px 10px 5px;
  font-size: 15px;
}

select.form-control {
  height: 32px !important;
}

#calendar-event-editor-modal .nav .active a {
  background: $usana-blue-200;
}

@media (max-width: 767px) {
  .eventDataSelector > .nav {
    width: 100% !important;
    max-width: 100% !important;
  }

  #calendar-event-editor-modal .list-group.dropdown-menu {
    width: 100% !important;
    max-width: 100% !important;
  }

  #calendar-event-editor-modal .panel-body .clearfix.btn-success {
    width: 100%;
    max-width: 100%;
    height: 32px;
  }
}

/*Top Navigation Admin Tools*/
#hub-u2d-btn div {
  display: inline;
}

#hub-u2d-btn .cms-item-edit-menu {
  display: none;
}

/*Main HUB Loader Spinner*/
#loaderDiv {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
}

.ajax-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 5%;
  max-height: 10%;
  transform: translate(-50%, -50%);
  display: block;
}

.button-loading-spinner {
  max-width: 20px;
  max-height: 20px;
}

a.list-group-item {
  color: $usana-blue-300;
  text-decoration: none;
}

a.list-group-item:focus,
a.list-group-item:hover {
  color: $usana-grey-800;
}

.disabled {
  color: $usana-grey-300;
  pointer-events: none;
  cursor: none;
}

.hidden {
  display: none;
}

.scroll-x {
  overflow-x: auto;
}

/*TODO: remove this as soon and we remove iflat. this is needed to override iflat.css so the disabled buttons are not purple*/
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: $usana-blue-400 !important;
  border-color: $usana-blue-400 !important;
}

.open .btn-primary.dropdown-toggle {
  background: $usana-blue-400 !important;
}

/*end TODO*/

.ot-sdk-row {
  font-size: 16px;
}

blockquote {
  font-style: italic;
  font-family: Georgia, Times, 'Times New Roman', serif;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
  margin: revert;
  padding-left: 20px;
  padding-right: 8px;
  border-left-width: 5px;
}
