@import 'bootstrap/scss/mixins/breakpoints';

.universal-modal.u-side-modal {
  .modal-dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 0;
    width: auto;
    min-width: 100%;
    transform: none;
    transition: 0.3s ease-in-out;

    @include media-breakpoint-up(sm) {
      min-width: 360px;
    }
  }

  .modal-content {
    height: 100%;
  }

  &.u-side-left {
    .modal-dialog {
      left: -25%;
    }

    &.show {
      .modal-dialog {
        left: 0;
      }
    }

    .modal-content {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.u-side-right {
    .modal-dialog {
      right: -25%;
    }

    &.show {
      .modal-dialog {
        right: 0;
      }
    }

    .modal-content {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .universal-modal-content {
    max-height: 100vh;
  }
}
