/************************/
/*** Home Page Design ***/
/************************/
.main-hub {
  &.main-hub-design {
    #content {
      @include media-breakpoint-down(sm) {
        position: relative;
        top: 0;
      }
    }
  }
}

//Overall Site Margin & row display
.homepage-design {
  .hub-page {
    margin-bottom: 60px;
  }

  .hub-page.with-user-alert {
    margin-bottom: 105px;
  }
}
