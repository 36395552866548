@import '_component-library-imports.scss';
/*************************/
/*** Global HUB Styles ***/
/*************************/

/*** Web Scrolling ***/

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 2px;
  background: $usana-grey-200;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 50px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
}

/*** Gloabal Flags ***/
.lh-0 {
  line-height: 0;
}

.flag-icon {
  margin-right: 1.25rem;
  position: relative;
  display: inline-block;
  width: 28px;
  height: 21px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  font-size: 15px;
  background-image: none;
  box-shadow: 0 0 22px 4px $usana-grey-100;
  &.australia,
  &.au,
  &.zh_AU,
  &.en_AU,
  &.zh_au_pc,
  &.en_au_pc {
    background-image: url('/hub/assets/images/australia.svg');
  }
  &.belgium,
  &.be,
  &.be_be,
  &.en_be {
    background-image: url('/hub/assets/images/belgium.svg');
  }
  &.canada,
  &.ca,
  &.zh_CA,
  &.en_CA,
  &.fr,
  &.zh_ca_pc,
  &.en_ca_pc,
  &.fr_ca_pc,
  &.fr_pc {
    background-image: url('/hub/assets/images/canada.svg');
  }
  &.china,
  &.zh_CN,
  &.en_CN,
  &.zh_us_pc {
    background-image: url('/hub/assets/images/china.png');
    -webkit-background-size: 110%;
    background-size: 110%;
  }
  &.colombia,
  &.co,
  &.es_CO,
  &.en_CO,
  &.es_co_pc {
    background-image: url('/hub/assets/images/colombia.svg');
  }
  &.europe,
  &.en_uk,
  &.es_uk,
  &.zh_uk,
  &.fr_fr,
  &.en_fr,
  &.zh_fr,
  &.de_de,
  &.en_de,
  &.zh_de,
  &.it_it,
  &.en_it,
  &.zh_it,
  &.ro_ro,
  &.en_ro,
  &.zh_ro,
  &.es_es,
  &.en_es,
  &.zh_es {
    background-image: url('/hub/assets/images/europe.png');
    -webkit-background-size: 110%;
    background-size: 110%;
  }
  &.france,
  &.fr_fr_pc {
    background-image: url('/hub/assets/images/france.svg');
  }
  &.germany,
  &.de,
  &.de_de_pc {
    background-image: url('/hub/assets/images/germany.svg');
  }
  &.hongkong,
  &.hk,
  &.zh_HK,
  &.en_HK,
  &.zh_hk_pc,
  &.en_hk_pc {
    background-image: url('/hub/assets/images/hongkong.svg');
  }
  &.india,
  &.en_IN,
  &.en_id_pc {
    background-image: url('/hub/assets/images/india.svg');
  }
  &.indonesia,
  &.id,
  &.in_ID,
  &.en_ID,
  &.in_id_pc,
  &.en_id_pc {
    background-image: url('/hub/assets/images/indonesia.svg');
  }
  &.italy,
  &.it,
  &.it_pc,
  &.en_it_pc,
  &.it_it_pc {
    background-image: url('/hub/assets/images/italy.svg');
  }
  &.japan,
  &.jp,
  &.jp_JP,
  &.en_JP,
  &.jp_pc {
    background-image: url('/hub/assets/images/japan.svg');
  }
  &.korea,
  &.kr,
  &.kr_KR,
  &.en_KR,
  &.kr_pc {
    background-image: url('/hub/assets/images/korea.svg');
  }
  &.malaysia,
  &.my,
  &.ms_MY,
  &.zh_MY,
  &.en_MY,
  &.zh_my_pc,
  &.en_my_pc {
    background-image: url('/hub/assets/images/malaysia.svg');
  }
  &.mexico,
  &.mx,
  &.es_MX,
  &.en_MX {
    background-image: url('/hub/assets/images/mexico.svg');
  }
  &.netherlands {
    background-image: url('/hub/assets/images/netherlands.svg');
  }
  &.newzealand,
  &.nz,
  &.zh_NZ,
  &.en_NZ,
  &.zh_nz_pc,
  &.en_nz_pc {
    background-image: url('/hub/assets/images/newzealand.svg');
  }
  &.philippines,
  &.ph,
  &.en_PH,
  &.en_ph_pc {
    background-image: url('/hub/assets/images/philippines.svg');
  }
  &.romania,
  &.ro,
  &.ro_ro_pc,
  &.en_ro_pc {
    background-image: url('/hub/assets/images/romania.svg');
  }
  &.singapore,
  &.sg,
  &.zh_SG,
  &.en_SG,
  &.zh_sg_pc,
  &.en_sg_pc {
    background-image: url('/hub/assets/images/singapore.svg');
  }
  &.spain,
  &.es,
  &.es_us_pc {
    background-image: url('/hub/assets/images/spain.svg');
  }
  &.taiwan,
  &.tw,
  &.zh_TW,
  &.en_TW,
  &.zh_tw_pc,
  &.en_tw_pc {
    background-image: url('/hub/assets/images/taiwan.svg');
  }
  &.thailand,
  &.th,
  &.th_TH,
  &.en_TH,
  &.th_th_pc,
  &.en_th_pc {
    background-image: url('/hub/assets/images/thailand.svg');
  }
  &.unitedkingdom,
  &.uk,
  &.en_uk_pc {
    background-image: url('/hub/assets/images/unitedkingdom.svg');
  }
  &.unitedstates,
  &.us,
  &.en_us_pc,
  &.zh,
  &.en,
  &.es {
    background-image: url('/hub/assets/images/unitedstates.svg');
  }
  &.current-market-flag {
    height: 36px;
    width: 48px;
    &:hover {
      opacity: 0.6;
    }
  }
  @include media-breakpoint-down(sm) {
    &.current-market-flag {
      height: 27px;
      width: 36px;
    }
  }
  &.flag-icon-topnav {
    margin-right: 0;
  }
}

a {
  text-decoration: none;
}

select,
select.form-select {
  border: 1px solid $usana-grey-400;
  border-radius: 4px;
  color: $usana-grey-800;
  cursor: pointer;
  height: 32px;
  font-size: 13px;
}

.form-select {
  color: $usana-grey-800;

  &.arrow-white {
    color: $usana-white-000;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }
  &.arrow-gray {
    color: $usana-grey-800;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23767676' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }
}

.btn.btn-primary:not(universal-button),
a.btn-primary {
  height: 32px;
  color: $usana-white-000;
  background: $usana-blue-400;
  border: none;

  &:hover,
  &:focus {
    background: $usana-blue-300;
    border: none;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
}

.btn.btn-secondary:not(universal-button),
a.btn-secondary {
  background: $usana-grey-200 !important;
  color: $usana-grey-900 !important;
  border: none !important;
  font-weight: 400;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.btn.btn-tertiary:not(universal-button),
a.btn-tertiary {
  background: transparent;
  color: $usana-blue-400;
  border: none;
  height: 32px;
  line-height: normal;

  &:hover,
  &:focus {
    background: $usana-blue-300;
    color: $usana-white-000;
    border: none;
    text-decoration: none;
  }

  &:active {
    background: $usana-blue-500;
    color: $usana-white-000;
    border: none;
  }
}

.btn.btn-white {
  background: $usana-white-000;
  color: $usana-grey-900;
  border: 2px solid $usana-grey-700;
  outline: 0;

  &:hover,
  &:focus {
    color: $usana-white-000;
    background: $usana-grey-900;
  }

  &:active {
    background: $usana-grey-500;
  }

  &:disabled {
    opacity: 0.32;
  }
}
