@import '_mixin-imports.scss';
@import '_component-library-imports.scss';

.elevation {
  border-radius: 4px !important;
}

.elevation2 {
  box-shadow: 0 1px 2px rgb(19 30 41 / 48%) !important;
}

.elevation4 {
  box-shadow: 0 2px 4px rgb(19 30 41 / 48%) !important;
}

.elevation8 {
  box-shadow: 0 4px 8px rgb(19 30 41 / 48%) !important;
}

.elevation16 {
  box-shadow: 0 8px 16px rgb(19 30 41 / 48%) !important;
}

.elevation24 {
  box-shadow: 0 16px 24px rgb(19 30 41 / 48%) !important;
}
