@import '_component-library-imports.scss';

/*****************************/
/*** Custom HUB CMS Styles ***/
/*****************************/
.cms-item {
  font-size: 16px;
  line-height: 24px;
  @include media-breakpoint-down(lg) {
    font-size: 15px;
    line-height: 23px;
  }
  @include media-breakpoint-down(md) {
    font-size: 14px;
    line-height: 22px;
  }
  p,
  ul,
  ol {
    font-size: inherit;
    line-height: inherit;
  }
}

.cms-styles {
  &.pageHeaders {
    margin: 0;
    &.pageHeadLine {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 5px;
    }
    &.pageSubTitle {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 5px;
    }
    &.pageHeaderSection {
      padding: 5px 0;
    }
  }
  &.bullet-list {
    list-style-type: disc;
    margin: 0 0 10px 0;
    li {
      margin-left: 0;
      padding-left: 3px;
    }
  }
  &.number-list {
    list-style-type: decimal;
    margin: 0 0 10px 0;
    li {
      margin-left: -5px;
      padding-left: 7px;
    }
  }
  &.mediaContainer {
    padding: 10px;
    box-shadow: 0 0 10px $usana-grey-100;
    border-radius: 4px;
    border: none;
    background-color: $usana-white-000;
    margin-bottom: 10px;
  }
  &.paragraph {
    padding: 5px 0;
    margin: 0;
  }
  &.disclaimer {
    font-size: 13px;
    font-weight: 300;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 40px;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cms-recognition-report-container-items {
  margin-top: 18px;
}

// all of these styles penetrate into cms content or purple pages
// so I don't know how to pull them out of the global stylesheet
.cms-item .cms-child-edit-mode {
  border: dashed $usana-grey-700 1px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 3px;
}

.row > div[container-edit-menu] > .cms-btn,
.row > div[include-children] > div[ng-repeat] > div[child-edit-menu] > .cms-item-edit-menu {
  margin-left: 15px;
}

.cms-item table {
  width: 100%;
  margin-bottom: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 1px solid $usana-grey-200;
}

.cms-item thead > tr > th,
.cms-item tbody > tr > th,
.cms-item tfoot > tr > th,
.cms-item thead > tr > td,
.cms-item tbody > tr > td,
.cms-item tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid $usana-grey-200;
  border-left: 1px solid $usana-grey-200;
}

.cms-item thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid $usana-grey-200;
}

.cms-item caption + thead > tr:first-child > th,
.cms-item colgroup + thead > tr:first-child > th,
.cms-item thead:first-child > tr:first-child > th,
.cms-item caption + thead > tr:first-child > td,
.cms-item colgroup + thead > tr:first-child > td,
.cms-item thead:first-child > tr:first-child > td {
  border-top: 0;
}

.cms-item tbody + tbody {
  border-top: 2px solid $usana-grey-200;
}

.cms-item table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.cms-item tbody > tr:nth-child(odd) > td,
.cms-item tbody > tr:nth-child(odd) > th {
  background-color: $usana-grey-100;
}

.cms-direct-oe-link-item {
  padding-bottom: 60px;
  background: $usana-white-000;
}

.cms-direct-oe-link-item .popover.top {
  margin-left: -11px;
  font-size: 10px;
  z-index: 10;
}

.cms-contest-item-content {
  padding-top: 30px;
  padding-left: 60px;
  padding-bottom: 35px;
}

.cms-content-item-title {
  padding: 20px 0;
  background-color: $usana-white-000;
}

.cms-content-item-heading {
  padding-left: 100px;
}

.stats-wrapper > div {
  overflow: auto;
}
