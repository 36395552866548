@import 'bootstrap/scss/functions';

//override variables
$enable-dark-mode: false;
@import 'bootstrap/scss/variables';

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';

.d-block {
  display: block !important;
}

@import 'bootstrap/scss/mixins/box-shadow';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/mixins/border-radius';
@import 'bootstrap/scss/mixins/transition';
@import 'bootstrap/scss/mixins/backdrop';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/modal';

@import 'bootstrap-modal-sizes';
@import 'bootstrap-side-modals';
