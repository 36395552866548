/***
When building individual components the spacing scale should always be used.
All USANA and Pando Design System components are built using this scale.
***/

.space-8 {
  padding: 8px;
}
.space-t-8 {
  padding: 8px 0 0 0;
}
.space-b-8 {
  padding: 0 0 8px 0;
}
.space-s-8 {
  padding: 0 0 0 8px;
}
.space-e-8 {
  padding: 0 8px 0 0;
}
.space-x-8 {
  padding: 0 8px;
}
.space-y-8 {
  padding: 8px 0;
}

.space-16 {
  padding: 16px;
}
.space-t-16 {
  padding: 16px 0 0 0;
}
.space-b-16 {
  padding: 0 0 16px 0;
}
.space-s-16 {
  padding: 0 0 0 16px;
}
.space-e-16 {
  padding: 0 16px 0 0;
}
.space-x-16 {
  padding: 0 16px;
}
.space-y-16 {
  padding: 16px 0;
}

.space-24 {
  padding: 24px;
}
.space-t-24 {
  padding: 24px 0 0 0;
}
.space-b-24 {
  padding: 0 0 24px 0;
}
.space-s-24 {
  padding: 0 0 0 24px;
}
.space-e-24 {
  padding: 0 24px 0 0;
}
.space-x-24 {
  padding: 0 24px;
}
.space-y-24 {
  padding: 24px 0;
}

.space-32 {
  padding: 32px;
}
.space-t-32 {
  padding: 32px 0 0 0;
}
.space-b-32 {
  padding: 0 0 32px 0;
}
.space-s-32 {
  padding: 0 0 0 32px;
}
.space-e-32 {
  padding: 0 32px 0 0;
}
.space-x-32 {
  padding: 0 32px;
}
.space-y-32 {
  padding: 32px 0;
}

.space-40 {
  padding: 40px;
}
.space-t-40 {
  padding: 40px 0 0 0;
}
.space-b-40 {
  padding: 0 0 40px 0;
}
.space-s-40 {
  padding: 0 0 0 40px;
}
.space-e-40 {
  padding: 0 40px 0 0;
}
.space-x-40 {
  padding: 0 40px;
}
.space-y-40 {
  padding: 40px 0;
}

.space-48 {
  padding: 48px;
}
.space-t-48 {
  padding: 48px 0 0 0;
}
.space-b-48 {
  padding: 0 0 48px 0;
}
.space-s-48 {
  padding: 0 0 0 48px;
}
.space-e-48 {
  padding: 0 48px 0 0;
}
.space-x-48 {
  padding: 0 48px;
}
.space-y-48 {
  padding: 48px 0;
}

.space-56 {
  padding: 56px;
}
.space-t-56 {
  padding: 56px 0 0 0;
}
.space-b-56 {
  padding: 0 0 56px 0;
}
.space-s-56 {
  padding: 0 0 0 56px;
}
.space-e-56 {
  padding: 0 56px 0 0;
}
.space-x-56 {
  padding: 0 56px;
}
.space-y-56 {
  padding: 56px 0;
}

.space-64 {
  padding: 64px;
}
.space-t-64 {
  padding: 64px 0 0 0;
}
.space-b-64 {
  padding: 0 0 64px 0;
}
.space-s-64 {
  padding: 0 0 0 64px;
}
.space-e-64 {
  padding: 0 64px 0 0;
}
.space-x-64 {
  padding: 0 64px;
}
.space-y-64 {
  padding: 64px 0;
}

.space-72 {
  padding: 72px;
}
.space-t-72 {
  padding: 72px 0 0 0;
}
.space-b-72 {
  padding: 0 0 72px 0;
}
.space-s-72 {
  padding: 0 0 0 72px;
}
.space-e-72 {
  padding: 0 72px 0 0;
}
.space-x-72 {
  padding: 0 72px;
}
.space-y-72 {
  padding: 72px 0;
}

.space-80 {
  padding: 80px;
}
.space-t-80 {
  padding: 80px 0 0 0;
}
.space-b-80 {
  padding: 0 0 80px 0;
}
.space-s-80 {
  padding: 0 0 0 80px;
}
.space-e-80 {
  padding: 0 80px 0 0;
}
.space-x-80 {
  padding: 0 80px;
}
.space-y-80 {
  padding: 80px 0;
}
