@import '_component-library-imports.scss';
@import '_absolute-centering.scss';
@import 'font-family';

html {
  font-size: 62.5%;
}

body {
  font-family: var(--default-font-family);
  font-size: 16px;
  line-height: 24px;
}

.homepage-design {
  a:hover,
  a:focus {
    outline: none;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before,
.container:after {
  content: ' ';
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before,
.container-fluid:after {
  content: ' ';
  display: table;
}

.container-fluid:after {
  clear: both;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857;
  color: $usana-grey-700;
  background-color: $usana-white-000;
  background-image: none;
  border: 1px solid $usana-grey-400;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 15px;

  &.form-error {
    .form-field-container {
      position: relative;
      width: fit-content;

      &:after {
        @extend .centered-vertical;
        content: '\f05e';
        font-family: $font-awesome-font-family;
        font-weight: 700;
        right: 8px;
        color: $usana-red-400;
      }
    }

    .form-control {
      border: 2px solid $usana-red-400;
      padding-right: 36px;
    }
  }
}

.error-message {
  margin-top: 2px;
  font-size: 14px;
  color: $usana-red-400;
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-sm {
  height: 30px;
  line-height: normal;
}

.input-lg {
  height: 45px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.33;
  border-radius: 6px;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 40px;
}

.has-feedback .form-control-feedback {
  position: absolute;
  top: 23px;
  right: 0;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio {
  color: $usana-green-300;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio {
  color: $usana-yellow-300;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio {
  color: $usana-red-300;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: $usana-grey-200;
}

.form-horizontal .control-label,
.form-horizontal .radio {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: ' ';
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover,
.btn:focus {
  color: $usana-white-000;
  text-decoration: none;
}

.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-default {
  color: $usana-white-000;
  background-color: $usana-grey-200;
  border-color: $usana-grey-200;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active {
  color: $usana-white-000;
  background-color: $usana-grey-200;
  border-color: $usana-grey-200;
}

.open .btn-default.dropdown-toggle {
  color: $usana-white-000;
  background-color: $usana-grey-200;
  border-color: $usana-grey-200;
}

.open > .dropdown-menu {
  display: block;
}

.btn-success,
.show > .btn-success.dropdown-toggle {
  color: $usana-white-000;
  background-color: $usana-green-400;
  border-color: $usana-green-400;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success:not(:disabled):not(.disabled):active {
  color: $usana-white-000;
  background-color: $usana-green-300;
  border-color: $usana-green-400;
}

.btn-info {
  color: $usana-white-000;
  background-color: $usana-blue-200;
  border-color: $usana-blue-200;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  color: $usana-white-000;
  background-color: $usana-blue-200;
  border-color: $usana-blue-200;
}

.btn-warning {
  color: $usana-white-000;
  background-color: $usana-yellow-200;
  border-color: $usana-yellow-200;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
  color: $usana-white-000;
  background-color: $usana-yellow-200;
  border-color: $usana-yellow-200;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.33;
  border-radius: 6px;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 13px;
  background-color: $usana-white-000;
  border: 1px solid $usana-grey-200;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: $usana-grey-800;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: $usana-white-000;
  background-color: $usana-grey-300;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  color: $usana-grey-300;
  text-align: center;
  background-color: $usana-grey-200;
  border: 1px solid $usana-grey-200;
  border-radius: 4px;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.btn-link,
.btn-link:hover {
  color: $usana-grey-800;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav:before,
.nav:after {
  content: ' ';
  display: table;
}

.nav:after {
  clear: both;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: $usana-grey-200;
}

.nav > li.disabled > a {
  color: $usana-grey-700;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: $usana-grey-700;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: $usana-grey-200;
  border-color: $usana-grey-800;
}

.nav .nav-divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: $usana-grey-200;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid $usana-grey-200;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover {
  border-color: $usana-grey-200;
}

.nav-tabs > li.active > a.nav-link,
.nav-tabs > li.active > a.nav-link:hover,
.nav-tabs > li.active > a.nav-link:focus {
  color: $usana-grey-800;
  background-color: $usana-grey-100;
  border: 1px solid $usana-grey-200;
  border-bottom-color: transparent;
  cursor: default;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 18px;
  border: 1px solid transparent;
}

.navbar:before,
.navbar:after {
  content: ' ';
  display: table;
}

.navbar:after {
  clear: both;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}

.pagination {
  border-radius: 4px;
  margin-bottom: 0;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  color: $usana-grey-800;
  background-color: $usana-grey-100;
  border: 1px solid $usana-grey-100;
  margin-left: -1px;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 2;
  cursor: default;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-success {
  background-color: $usana-green-300;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: $usana-green-400;
}

.label-danger {
  background-color: $usana-red-300;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: $usana-red-400;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: $usana-white-000;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: $usana-grey-700;
  border-radius: 10px;
}

.badge:empty {
  display: none;
}

a.badge:hover,
a.badge:focus {
  color: $usana-white-000;
  text-decoration: none;
  cursor: pointer;
}

.progress {
  overflow: hidden;
  height: 18px;
  margin-bottom: 18px;
  background-color: $usana-white-000;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 18px;
  color: $usana-white-000;
  text-align: center;
  background-color: $usana-blue-300;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}

.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.media,
.media .media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .list-group {
    margin-bottom: 0;
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: $usana-white-000;
  border: 1px solid $usana-grey-200;
}

.list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.panel {
  margin-bottom: 18px;
  background-color: $usana-white-000;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  padding: 15px;
}

.panel-body:before,
.panel-body:after {
  content: ' ';
  display: table;
}

.panel-body:after {
  clear: both;
}

.remove-panel-body-padding {
  .panel-body {
    padding: 0;
  }
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: inherit;
}

.panel-title > a {
  color: inherit;
}

.panel-footer {
  padding: 10px 15px;
  background-color: $usana-white-000;
  border-top: 1px solid $usana-grey-100;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel-group {
  margin-bottom: 18px;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
  overflow: hidden;
}

.panel-default {
  border-color: $usana-grey-100;
}

.panel-default > .panel-heading {
  color: $usana-grey-800;
  border-color: $usana-grey-100;
}

.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top-color: $usana-grey-100;
}

.panel-default > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: $usana-grey-100;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: $usana-white-000;
  border: 1px solid $usana-grey-200;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}

.close {
  float: right;
  font-size: 19.5px;
  font-weight: bold;
  line-height: 1;
  color: $usana-black-000;
  text-shadow: 0 1px 0 $usana-white-000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.alert-dismissible {
  button.btn-close {
    font-size: 12px;
  }
}

.modal-open {
  overflow: hidden;
}

.modal:not(.universal-modal) {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
  }

  .modal-content {
    position: relative;
    background-color: $usana-white-000;
    border: 1px solid $usana-grey-800;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: none;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: black;
}

.modal-backdrop.fade:not(.show) {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-header {
  padding: 16px;
  border-bottom: 1px solid $usana-grey-200;
  min-height: 16.42857px;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857;
}

.modal-body {
  position: relative;
  padding: 16px;
}

.modal-footer {
  margin-top: 0;
  padding: 16px;
  text-align: right;
  border-top: 1px solid $usana-grey-200;
}

.modal-footer:before,
.modal-footer:after {
  content: ' ';
  display: table;
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 95vh;

  @include media-breakpoint-up(sm) {
    max-height: min(85vh, 720px);
  }

  &.confirmation-modal {
    max-height: 95vh;

    @include media-breakpoint-up(sm) {
      max-height: 320px;
    }

    @include media-breakpoint-up(md) {
      max-height: min(85vh, 576px);
    }

    @include media-breakpoint-up(lg) {
      max-height: min(85vh, 720px);
    }
  }

  .modal-body {
    overflow-y: auto;
  }
}

@include media-breakpoint-up(sm) {
  .modal:not(.universal-modal) {
    .modal-dialog {
      width: 600px;
      margin: 30px auto;
      max-width: 100%;
    }

    .modal-content {
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
  }

  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
}

.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: $usana-white-000;
  text-align: center;
  text-decoration: none;
  background-color: $usana-black-000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: $usana-black-000;
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: $usana-black-000;
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  right: 5px;
  border-width: 5px 5px 0;
  border-top-color: $usana-black-000;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: $usana-black-000;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: $usana-black-000;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $usana-black-000;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: $usana-black-000;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: $usana-black-000;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.invisible {
  visibility: hidden;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.no-margin {
  margin: 0;
}

.space {
  display: inline;
  padding: 5px;
}

p {
  margin: 0 0 9px;
}

.form-control {
  border-width: 1px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

select.form-control {
  appearance: auto !important;
}

.btn-line-primary,
.btn-line-danger {
  background-color: transparent;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: $usana-white-000;
}

.has-error .form-control {
  border-color: $usana-red-300;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.badge-success {
  background-color: $usana-green-400;
}

.badge-warning {
  background-color: $usana-yellow-300;
}

.label {
  padding: 0.5em 0.8em;
}

.list-group-item {
  padding: 15px;
  border: 1px solid $usana-grey-100;
}

.panel .panel-heading {
  padding: 15px;
  text-transform: uppercase;
}

.panel .panel-title {
  font-size: 13px;
}

.panel .panel-footer {
  padding: 15px;
}

.pagination > li > a {
  cursor: pointer;
}

.pagination > li > a,
.pagination > li > span {
  margin-left: 1px;
}

.progress {
  border-radius: 50px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: $usana-grey-100;
}

.progress-bar {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.page {
  padding: 15px;
}

.top-header {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 160px;
  background-color: $usana-white-000;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.media {
  display: block;
}

.btn .caret {
  margin-left: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

:host:not(.exclude-bootstrap-override) {
  .col-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

[data-ng-click] {
  cursor: pointer;
}

[data-ng-click]:hover {
  text-decoration: none;
}

#dlmLoading {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $usana-black-000;
  opacity: 0.3;
  z-index: 90;
  text-align: center;
  position: fixed;

  img {
    position: relative;
    top: 50%;
    width: 5%;
    height: auto;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
