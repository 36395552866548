@import '_component-library-imports.scss';
@import '_mixin-imports.scss';

/****************/
/*** Side Nav ***/
/****************/
svg {
  border: none !important;
}

.homepage-design {
  -webkit-overflow-scrolling: auto !important;
  .clearfix {
    position: relative;
    top: 0;
  }

  #nav.hub-nav > li > a.nav-enroll-container {
    display: block;
    color: $usana-grey-800;
    text-decoration: none;
    padding: 5px 0;
  }

  #nav.hub-nav > li:hover > a.nav-enroll-container {
    background-color: transparent;
  }

  .side-nav-title {
    color: $usana-grey-700;
    font-weight: 500;
  }

  .col-main-content {
    width: calc(100% - 50px);
    min-height: 500px;
    padding: 16px;
  }
  @include media-breakpoint-down(md) {
    .col-main-content {
      width: 100%;
    }
  }
}

.tab-content .img-responsive {
  margin-bottom: 20px;
}

/*****************************/
/*** Left Navigation Modal ***/
/*****************************/
.modal:not(.universal-modal) {
  &.left {
    .modal-dialog {
      left: 50px;
      z-index: 0;
      top: 0;
      position: fixed;
      margin: auto;
      width: 100%;
      max-width: 100%;
      height: 100%;

      @include media-breakpoint-up(md) {
        width: 320px;
      }

      @include media-breakpoint-down(md) {
        left: 0;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        left: 7%;
      }
    }

    .modal-content {
      height: 100%;
      overflow-y: auto;
      border-radius: 0;
      border: none;
      box-shadow: none;
      background-color: $usana-grey-100;

      .modal-header {
        border-bottom: none;
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.sticky {
  position: fixed;
  top: 0;
  width: calc(100% - 8px);
  z-index: 100;
  box-shadow: 0 0 10px $usana-grey-100;
}

@-moz-document url-prefix() {
  .sticky {
    width: 100%;
  }
}

.pl-md-8 {
  @media (min-width: 768px) and (max-width: 1080px) {
    padding-left: 8% !important;
  }
  @media (min-width: 1200px) and (max-width: 1300px) {
    padding-left: 7.5% !important;
  }
}
