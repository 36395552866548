.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.centered-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
