@import 'bootstrap/scss/mixins/breakpoints';

.universal-modal {
  .modal-dialog {
    margin: auto;
    padding-top: 6px;

    width: 100vw;
    max-width: 100vw;

    @include media-breakpoint-up(sm) {
      width: 500px;
      max-width: 500px;
    }

    @include media-breakpoint-up(md) {
      width: 576px;
      max-width: 576px;
    }

    @include media-breakpoint-up(lg) {
      width: 672px;
      max-width: 672px;
    }
  }

  .modal-content {
    min-height: 150px;
  }
}
