/*
    Colors:
*/

$usana-white-000: #ffffff;
$usana-grey-100: #f4f4f4;
$usana-grey-200: #dbe2e9;
$usana-grey-300: #a6bbc8;
$usana-grey-400: #7a99ac;
$usana-grey-500: #688197;
$usana-grey-600: #57728b;
$usana-grey-700: #425563;
$usana-grey-800: #333f48;
$usana-grey-900: #131e29;
$usana-black-000: #000000;
$usana-blue-100: #b9d9eb;
$usana-blue-200: #69b4e7;
$usana-blue-300: #418fde;
$usana-blue-400: #0074cc;
$usana-blue-500: #0050b5;
$usana-blue-600: #003385;
$usana-teal-100: #8ce2d0;
$usana-teal-200: #49c5b1;
$usana-teal-300: #00b398;
$usana-teal-400: #009b77;
$usana-teal-500: #006a52;
$usana-teal-600: #004c45;
$usana-green-100: #d4eb8e;
$usana-green-200: #97d700;
$usana-green-300: #84bd00;
$usana-green-400: #509e2f;
$usana-green-500: #4a7729;
$usana-green-600: #1c4220;
$usana-yellow-100: #f8e59a;
$usana-yellow-200: #ffc658;
$usana-yellow-300: #ffb81c;
$usana-yellow-400: #f2961f;
$usana-yellow-500: #d45d00;
$usana-yellow-600: #b94700;
$usana-red-100: #fabbcb;
$usana-red-200: #ff8da1;
$usana-red-300: #ff585d;
$usana-red-400: #ee2737;
$usana-red-500: #d22630;
$usana-red-600: #a4343a;
$usana-fuscia-100: #eabedb;
$usana-fuscia-200: #f277c6;
$usana-fuscia-300: #e93cac;
$usana-fuscia-400: #e10098;
$usana-fuscia-500: #c6007e;
$usana-fuscia-600: #a20067;
$usana-purple-100: #eab8e4;
$usana-purple-200: #dd7ed3;
$usana-purple-300: #c964cf;
$usana-purple-400: #b0008e;
$usana-purple-500: #890376;
$usana-purple-600: #6d2077;
$usana-silver-100: #d1dde6;
$usana-silver-200: #a6bbc8;
$usana-silver-300: #7a99ac;
$usana-silver-400: #4f758b;
$usana-silver-500: #34657f;
$usana-silver-600: #003e51;
$usana-spruce-100: #d1e0d7;
$usana-spruce-200: #94b7bb;
$usana-spruce-300: #7fa9ae;
$usana-spruce-400: #4f868e;
$usana-spruce-500: #115e67;
$usana-spruce-600: #004851;
$usana-rose-100: #e4bec3;
$usana-rose-200: #c48490;
$usana-rose-300: #b46b7a;
$usana-rose-400: #984856;
$usana-rose-500: #912f46;
$usana-rose-600: #651d32;

$z-index-poppers: 100;

/*
    Font Styles:
*/

@property --default-font-family {
  syntax: '<font-family>';
  initial-value: Lato;
  inherits: false;
}

/* Desktop/Italic/Regular font style */
$font-desktop-italic-regular-font-size: 14px;
$font-desktop-italic-regular-line-height: 21px;
$font-desktop-italic-regular-text-align: center;
$font-desktop-italic-regular-color: #000000;
$font-desktop-italic-regular-font-style: italic;
$font-desktop-italic-regular-font-weight: 400;
$font-desktop-italic-regular-font-family: Georgia;

@mixin font-desktop-italic-regular() {
  font-size: $font-desktop-italic-regular-font-size;
  line-height: $font-desktop-italic-regular-line-height;
  text-align: $font-desktop-italic-regular-text-align;
  color: $font-desktop-italic-regular-color;
  font-style: $font-desktop-italic-regular-font-style;
  font-weight: $font-desktop-italic-regular-font-weight;
  font-family: $font-desktop-italic-regular-font-family;
}

/* Desktop/Italic/Bold font style */
$font-desktop-italic-bold-font-size: 14px;
$font-desktop-italic-bold-line-height: 21px;
$font-desktop-italic-bold-text-align: center;
$font-desktop-italic-bold-color: #000000;
$font-desktop-italic-bold-font-style: italic;
$font-desktop-italic-bold-font-weight: bold;
$font-desktop-italic-bold-font-family: Georgia;

@mixin font-desktop-italic-bold() {
  font-size: $font-desktop-italic-bold-font-size;
  line-height: $font-desktop-italic-bold-line-height;
  text-align: $font-desktop-italic-bold-text-align;
  color: $font-desktop-italic-bold-color;
  font-style: $font-desktop-italic-bold-font-style;
  font-weight: $font-desktop-italic-bold-font-weight;
  font-family: $font-desktop-italic-bold-font-family;
}

$font-heading-font-weight: 700;
$font-heading-font-family: var(--default-font-family);

@mixin font-heading-common() {
  font-weight: $font-heading-font-weight;
  font-family: $font-heading-font-family;
}

$font-heading-1-desktop-font-size: 40px;
$font-heading-1-desktop-line-height: 50px;

@mixin font-heading-1-desktop() {
  @include font-heading-common();
  font-size: $font-heading-1-desktop-font-size;
  line-height: $font-heading-1-desktop-line-height;
}

$font-heading-1-mobile-font-size: 36px;
$font-heading-1-mobile-line-height: 45px;

@mixin font-heading-1-mobile() {
  @include font-heading-common();
  font-size: $font-heading-1-mobile-font-size;
  line-height: $font-heading-1-mobile-line-height;
}

$font-heading-2-desktop-font-size: 32px;
$font-heading-2-desktop-line-height: 40px;

@mixin font-heading-2-desktop() {
  @include font-heading-common();
  font-size: $font-heading-2-desktop-font-size;
  line-height: $font-heading-2-desktop-line-height;
}

$font-heading-2-mobile-font-size: 28px;
$font-heading-2-mobile-line-height: 35px;

@mixin font-heading-2-mobile() {
  @include font-heading-common();
  font-size: $font-heading-2-mobile-font-size;
  line-height: $font-heading-2-mobile-line-height;
}

$font-heading-3-desktop-font-size: 24px;
$font-heading-3-desktop-line-height: 30px;

@mixin font-heading-3-desktop() {
  @include font-heading-common();
  font-size: $font-heading-3-desktop-font-size;
  line-height: $font-heading-3-desktop-line-height;
}

$font-heading-3-mobile-font-size: 20px;
$font-heading-3-mobile-line-height: 25px;

@mixin font-heading-3-mobile() {
  @include font-heading-common();
  font-size: $font-heading-3-mobile-font-size;
  line-height: $font-heading-3-mobile-line-height;
}

$font-heading-4-font-size: 18px;
$font-heading-4-line-height: 22.5px;

@mixin font-heading-4() {
  @include font-heading-common();
  font-size: $font-heading-4-font-size;
  line-height: $font-heading-4-line-height;
}

$font-heading-5-font-size: 16px;
$font-heading-5-line-height: 20px;

@mixin font-heading-5() {
  @include font-heading-common();
  font-size: $font-heading-5-font-size;
  line-height: $font-heading-5-line-height;
}

$font-heading-6-font-size: 14px;
$font-heading-6-line-height: 17.5px;

@mixin font-heading-6() {
  @include font-heading-common();
  font-size: $font-heading-6-font-size;
  line-height: $font-heading-6-line-height;
}

/* news caption font style */
$font-news-caption-font-size: 14px;
$font-news-caption-line-height: 16px;
$font-news-caption-text-align: start;
$font-news-caption-color: #000000;
$font-news-caption-font-style: normal;
$font-news-caption-font-weight: 400;
$font-news-caption-font-family: var(--default-font-family);

@mixin font-news-caption() {
  font-size: $font-news-caption-font-size;
  line-height: $font-news-caption-line-height;
  text-align: $font-news-caption-text-align;
  color: $font-news-caption-color;
  font-style: $font-news-caption-font-style;
  font-weight: $font-news-caption-font-weight;
  font-family: $font-news-caption-font-family;
}

/* Body font style */
$font-body-font-size: 16px;
$font-body-line-height: 24px;
$font-body-text-align: start;
$font-body-font-style: normal;
$font-body-font-weight: 400;
$font-body-font-family: var(--default-font-family);

@mixin font-body() {
  font-size: $font-body-font-size;
  line-height: $font-body-line-height;
  text-align: $font-body-text-align;
  font-style: $font-body-font-style;
  font-weight: $font-body-font-weight;
  font-family: $font-body-font-family;
}

/* Body 2 font style */
$font-body-2-font-size: 14px;
$font-body-2-line-height: 21px;
$font-body-2-text-align: start;
$font-body-2-font-style: normal;
$font-body-2-font-weight: 400;
$font-body-2-font-family: var(--default-font-family);

@mixin font-body-2() {
  font-size: $font-body-2-font-size;
  line-height: $font-body-2-line-height;
  text-align: $font-body-2-text-align;
  font-style: $font-body-2-font-style;
  font-weight: $font-body-2-font-weight;
  font-family: $font-body-2-font-family;
}

/* Body 3 font style */
$font-body-3-font-size: 12px;
$font-body-3-line-height: 18px;
$font-body-3-text-align: start;
$font-body-3-font-style: normal;
$font-body-3-font-weight: 400;
$font-body-3-font-family: var(--default-font-family);

@mixin font-body-3() {
  font-size: $font-body-3-font-size;
  line-height: $font-body-3-line-height;
  text-align: $font-body-3-text-align;
  font-style: $font-body-3-font-style;
  font-weight: $font-body-3-font-weight;
  font-family: $font-body-3-font-family;
}

/* Body Italic font style */
$font-body-italic-font-size: 16px;
$font-body-italic-line-height: 24px;
$font-body-italic-text-align: start;
$font-body-italic-font-style: italic;
$font-body-italic-font-weight: 400;
$font-body-italic-font-family: Georgia;

@mixin font-body-italic() {
  font-size: $font-body-italic-font-size;
  line-height: $font-body-italic-line-height;
  text-align: $font-body-italic-text-align;
  font-style: $font-body-italic-font-style;
  font-weight: $font-body-italic-font-weight;
  font-family: $font-body-italic-font-family;
}

$font-subheadings-1-font-size: 20px;
$font-subheadings-1-font-color: $usana-grey-900;
$font-subheadings-1-text-align: left;
$font-subheadings-1-line-height: 25px;

@mixin font-subheadings-1() {
  font-size: $font-subheadings-1-font-size;
  color: $font-subheadings-1-font-color;
  text-align: $font-subheadings-1-text-align;
  line-height: $font-subheadings-1-line-height;
}

$font-subheadings-2-font-size: 16px;
$font-subheadings-2-font-color: $usana-grey-900;
$font-subheadings-2-text-align: left;
$font-subheadings-2-line-height: 25px;
$font-subheadings-2-text-transform: uppercase;

@mixin font-subheadings-2() {
  font-size: $font-subheadings-2-font-size;
  color: $font-subheadings-2-font-color;
  text-align: $font-subheadings-2-text-align;
  line-height: $font-subheadings-2-line-height;
  text-transform: $font-subheadings-2-text-transform;
}

$font-subheadings-3-font-size: 16px;
$font-subheadings-3-font-color: $usana-grey-900;
$font-subheadings-3-text-align: left;
$font-subheadings-3-line-height: 25px;
$font-subheadings-3-text-transform: uppercase;

@mixin font-subheadings-3() {
  font-size: $font-subheadings-3-font-size;
  color: $font-subheadings-3-font-color;
  text-align: $font-subheadings-3-text-align;
  line-height: $font-subheadings-3-line-height;
  text-transform: $font-subheadings-3-text-transform;
}

$elevation-2-box-shadow: 0 1px 2px rgb(19 30 41 / 48%);
$elevation-4-box-shadow: 0 2px 4px rgb(19 30 41 / 48%);
$elevation-8-box-shadow: 0 4px 8px rgb(19 30 41 / 48%);
$elevation-16-box-shadow: 0 8px 16px rgb(19 30 41 / 48%);
$elevation-24-box-shadow: 0 16px 24px rgb(19 30 41 / 48%);
