@import '@usana/ux/sass/style-params';

.theme-usana {
  .bs-datepicker-head {
    .previous,
    .next {
      color: $usana-grey-600;
    }

    .current {
      border: solid 1px $usana-grey-200;
      border-radius: 5px;
      color: $usana-grey-700;
    }
  }

  .bs-datepicker-body table td.week span {
    color: $usana-blue-400 !important;
  }

  .bs-datepicker-body {
    border: none;

    table {
      th {
        color: $usana-blue-400;
      }

      tbody {
        td {
          span {
            color: $usana-grey-700;

            &.today {
              text-decoration: underline;
              color: $usana-blue-500;
            }

            &:hover {
              background-color: $usana-grey-400 !important;
              color: $usana-white-000 !important;
            }

            &.selected {
              color: $usana-white-000;
              background-color: $usana-blue-400;
            }

            &.is-other-month {
              color: $usana-grey-300;
            }
          }
        }
      }
    }
  }
}
