@import '_component-library-imports.scss';
@import '_absolute-centering.scss';
@import 'font-family';

/**************************/
/* DLM Module View Styles */
/**************************/

@media (min-width: 1200px) {
  .col-xl-3 {
    width: 25% !important;
    max-width: 25% !important;
  }
  .col-xl-9 {
    width: 75% !important;
    max-width: 75% !important;
  }
  .col-xl-8 {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
}

/* applies to all HUB */
.main-hub-design,
#dlm-container-view2,
.footer,
.modal-content {
  font-family: var(--default-font-family);
}

#dlm-container-view2 {
  width: 100%;
  height: 100%;
}

#dlm-container-view2 .report-table-container {
  background: rgb(255, 255, 255, 0.5);
  scroll-behavior: smooth;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding-right: 0;
    padding-left: 0;
  }

  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

#dlm-container-view2 {
  .report-full-display {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: calc(100% - 35px);
    }
  }
}

#dlm-container-view2 .panel-default {
  border: none;
}

#dlm-container-view2 input.search-btn {
  margin: 0 auto;
  border-color: $usana-grey-400;
  color: $usana-grey-900;
  font-weight: 400;
  font-size: 14px;
}

#dlm-container-view2 .btn-sm {
  background: transparent;
  border-color: $usana-grey-100;
  color: $usana-grey-200;
}

#dlm-container-view2 .dlm-report-section .list-group {
  display: initial;
}

#dlm-container-view2 .dlm-report-section .fontawesome-icons {
  opacity: 1;
  position: relative;
  right: -13px;
  top: -4.5px;
}

#dlm-container-view2 .grid-col-header {
  background: $usana-blue-300;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}

#dlmFiltersModal {
  max-height: 585px;
  overflow-y: auto;
  @media (max-width: 767px) {
    .row {
      display: block;
    }
  }
}

#dlm-container-view2 {
  //see value in report-footer.component.scss
  .pagination {
    margin: 0;
  }

  .pagination > li > a {
    background: transparent;
    border: none;
    font-size: 13px;
    color: $usana-blue-400;
  }

  .pagination > .disabled > a {
    background: transparent;
    color: $usana-grey-900;
    border: none;
  }

  .pagination > .active > a {
    background-color: transparent !important;
    color: $usana-grey-900 !important;
    border: none;
  }
}

#dlm-container-view2 #exportEmail {
  font-weight: 400;
}

#dlm-container-view2 .fontawesome-icons {
  vertical-align: middle;
}

#dlm-container-view2 .dropdown-menu > li > a {
  @include media-breakpoint-down(sm) {
    white-space: normal;
  }
}

#dlm-container-view2 .btn {
  font-weight: 400;
}

#dlm-container-view2 .hide {
  display: none !important;
}

.dlm-expand-list-icons {
  vertical-align: middle;
}

.cursor-0 {
  cursor: default !important;
}

.dlm-row {
  display: table;
  content: '';
  width: 100%;
}

/*************************/
/** Mobile view Filters **/
/*************************/
.filters-btn {
  margin-right: 7px;
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    display: inline-block;
  }
}

/************************/
/*** Filters Sections ***/
/************************/

#dlm-advanced-options-modal {
  .modal-footer {
    border: none;
  }

  @media only screen and (max-width: 767px) and (orientation: landscape) {
    height: 300px;
    overflow: auto;
  }
}

.dlm-display-container {
  display: block;
  width: 100%;
}

.filter-display-box {
  width: 50%;
  margin-bottom: 5px;
}

.filter-display-box:nth-child(odd) {
  padding-left: 0;
  padding-right: 0.25rem;
}

.filter-display-box:nth-child(even) {
  padding-left: 0.25rem;
  padding-right: 0;
}

.filter-display-option-btn {
  border: solid 1px $usana-grey-100;
  border-radius: 5px;
  background-color: $usana-white-000;
  padding: 10px;
  width: 100%;
  word-wrap: break-word;
  min-height: 82px;
  font-size: 11px;

  .fontawesome-icons {
    display: block;
    margin-bottom: 5px;
    font-size: 20px;
    left: 0;
    padding: 2px;
  }
}

.filter-display-option-selected {
  background-color: $usana-blue-300;
  color: $usana-white-000;

  &:focus,
  &:hover {
    outline: none;
  }
}

/***********************/
/*** Calendar Filter ***/
/***********************/

#dlm-container-view2 .uib-daypicker,
#dlm-advanced-options-modal .uib-daypicker {
  background: #fff;
}

#dlm-container-view2 div[uib-datepicker-popup-wrap] {
  z-index: 5;
  @include media-breakpoint-up(md) {
    position: fixed;
    z-index: 50;
    top: 50% !important;
    left: 6%;
  }
  @media screen and (orientation: landscape) {
    top: -31px;
    z-index: 80;
  }
}

#dlm-container-view2 .uib-datepicker-popup.dropdown-menu {
  top: -10px !important;
  overflow: auto;
  @include media-breakpoint-down(md) {
    overflow: hidden;
    z-index: 4;
  }
  @media screen and (orientation: landscape) {
    overflow: auto;
  }

  &:after {
    z-index: 100000;
  }
}

#dlm-container-view2 .uib-daypicker table {
  min-width: 100%;
}

#dlm-container-view2 table button,
#dlm-advanced-options-modal table button {
  padding: 8px 10px;
  border: none;
  @media (min-width: 0px) and (max-width: 359px) {
    padding: 4px 6px;
  }
  @media (min-width: 360px) and (max-width: 374px) {
    padding: 7px 9px;
  }

  .text-info {
    color: unset !important;
  }

  .text-muted {
    color: $usana-grey-200 !important;
  }
}

#dlm-container-view2 table thead th,
#dlm-advanced-options-modal table thead th {
  text-transform: uppercase;
  color: $usana-grey-500;
}

#dlm-container-view2 table button.btn-sm,
#dlm-advanced-options-modal table button.btn-sm {
  color: $usana-grey-900;
}

#dlm-container-view2 table .btn.active,
#dlm-advanced-options-modal table .btn.active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  background: $usana-blue-400 !important;
  color: $usana-white-000;
  font-weight: 900;
}

#dlm-container-view2 table thead tr:first-child,
#dlm-advanced-options-modal table thead tr:first-child {
  border-bottom: solid 1px $usana-grey-100;
}

#dlm-container-view2 .uib-button-bar .btn-info,
#dlm-container-view2 .uib-button-bar .btn-success,
#dlm-advanced-options-modal .uib-button-bar .btn-info,
#dlm-advanced-options-modal .uib-button-bar .btn-success {
  background: $usana-blue-300;
  color: $usana-white-000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-right: 10px;

  &:hover,
  &:focus {
    background: $usana-blue-300;
    opacity: 0.8;
  }
}

#dlm-container-view2 .uib-button-bar .btn-success,
#dlm-advanced-options-modal .uib-button-bar .btn-success {
  margin-right: 0;

  &.pull-right {
    padding-right: 10px;
  }

  &:hover,
  &:focus {
    border: none;
  }
}

#dlm-container-view2 .uib-button-bar .btn-danger,
#dlm-advanced-options-modal .uib-button-bar .btn-danger {
  background: $usana-grey-100;
  color: $usana-grey-900;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $usana-grey-100;
    opacity: 0.8;
  }
}

#dlm-advanced-options-modal .uib-datepicker-popup table .btn-default.btn-sm {
  background: transparent;
}

/***************************/
/*** Report/Tree Buttons ***/
/***************************/
#dlm-container-view2 {
  .no-border-radius-right {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .no-border-radius-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-plain {
    color: $usana-white-000;
  }
}

#dlm-container-view2 {
  .btn-line-primary-dark {
    color: $usana-grey-900;

    &:hover {
      background-color: $usana-grey-100 !important;
      color: $usana-grey-900 !important;
      border-color: $usana-grey-100 !important;
    }
    &:focus {
      outline: 0;
    }
  }
}

/*********************************/
/*** Hide TopNav - Mobile Only ***/
/*********************************/

.hideTopNav {
  display: none !important;
}

/*******************************/
/*** Fix Report Table Mobile ***/
/*******************************/
@media only screen and (max-width: 767px) {
  #hub-xs-nav.top-header {
    position: fixed;
  }
  #dlm-container-view2 .report-table-container {
    overflow: visible !important;
  }
  #dlm-container-view2 .table {
    margin-bottom: 0 !important;
    height: auto !important;
  }
}

#dlm-container-view2 .smallHeight {
  max-height: 230px !important;
}

.calendar-icon,
.calendar-icon1 {
  position: absolute !important;
  right: 13px !important;
  top: 40px !important;
  display: inline;
  width: 15%;
  z-index: 1;
  cursor: pointer;
  font-size: 18px;
  @media (max-width: 1365px) {
    display: none;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

input.is-invalid {
  border-color: $usana-red-500 !important;
  box-shadow: none;
}

/*****************************/
/*** Modal Footer Position ***/
/*****************************/
#dlmFiltersModal {
  position: relative;
  top: 0;
  @include media-breakpoint-down(sm) {
    max-height: 450px;
  }

  .modal-header {
    position: sticky;
    top: 0;
    background: $usana-white-000;
    z-index: 10;
  }

  .modal-body {
    z-index: 1;
    margin-bottom: 70px;
  }

  .modal-footer {
    position: fixed;
    @include media-breakpoint-down(sm) {
      -webkit-transform: translate3d(0, -1px, 0);
      -ms-transform: translate3d(0, -1px, 0);
      transform: translate3d(0, -1px, 0);
    }
    bottom: 1px;
    width: 98.4%;
    left: 1px;
    background: $usana-white-000;
    padding: 16px;
    z-index: 1000;
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .modal-footer {
      position: relative;
      width: 100%;
      left: 0;
    }
  }
}

/******************************/
/*** Direct Enrollment Link ***/
/******************************/
.hub-direct-link-icons {
  vertical-align: middle;
  margin-right: 5px;
  font-size: 15px;
  cursor: pointer;
}

.hub-direct-link-label {
  min-height: 28px;
  vertical-align: middle;
  padding-top: 5px;
}

.hub-direct-link-switch-container {
  background: $usana-grey-200;
  border-radius: 25px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px 0;
  .hub-direct-link-left-col,
  .hub-direct-link-right-col {
    width: 50%;
    position: relative;
  }
}

.hub-direct-link-switch-mask,
.hub-direct-link-switch-mask-active {
  display: inline-block;
  border-radius: 25px;
  padding: 5px 10px;
  width: 100%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    margin: 0 !important;
  }
}

.checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 33px;
  width: 99%;
  background: $usana-grey-200;
  border-radius: 25px;
  color: $usana-grey-500;
}

.hub-direct-link-switch-container input:checked ~ .checkmark {
  background-color: $usana-white-000;
  color: $usana-blue-300;
  box-shadow: 0 0 5px $usana-grey-100;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.hub-direct-link-switch-container input:checked ~ .checkmark:after {
  display: block;
}

.checkmark-label {
  position: relative;
  top: 5px;
}

.hub-direct-link-url {
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
}

#socialSharing {
  overflow: hidden;
  position: relative;
}

#socialSharingTextBox {
  position: absolute;
  left: -99999px;
  height: 0;
  width: 0;
  font-size: 16px;
}

#copyToClipboardBtn {
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
}

.hub-direct-link-image {
  @include media-breakpoint-up(lg) {
    padding-left: 37px;
  }
}

.hub-direct-link-btn {
  display: block;
}

.cms-direct-oe-link-item {
  .p-11 {
    padding-left: 11px;
    padding-right: 11px;
  }
}

/*********************/
/*** Widget Charts ***/
/*********************/

.dlm-widget-box {
  box-shadow: 0 0 10px $usana-grey-100;
  border-radius: 5px;
  border: none;
  @include media-breakpoint-up(md) {
    min-height: 443px;
  }
  .card-header {
    background: $usana-white-000;
    border-bottom: solid 1px $usana-grey-100;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 16px;
    @include media-breakpoint-down(md) {
      padding: 16px;
    }
    .dlm-widget-chart-title {
      font-size: 18px;
      padding: 0.7rem 0;
      font-weight: 600;
      color: $usana-grey-900;
      line-height: 22.5px;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 20px;
      }
      .widget-header-icon {
        font-weight: 600;
      }
    }
    .row {
      display: flex !important;
      justify-items: center;
      align-items: center;
      flex-wrap: unset;
    }
  }
  .card-body {
    padding: 16px;
    @include media-breakpoint-down(md) {
      padding: 16px;
    }
  }

  .widget-box {
    .dlm-widget-dataset-bc-container.col-12,
    .dlm-widget-dataset-4weekcycle-container.col-12 {
      padding-left: 53px;
    }
    .widget-arrow-icon {
      position: absolute;
      top: -1px;
      z-index: 70;
      font-size: 15px;
      color: $usana-grey-800;
      &.prev-arrow {
        left: 7px;
        @include media-breakpoint-up(sm) {
          left: 21px;
        }
      }
      &.next-arrow {
        right: 0;
        @include media-breakpoint-up(sm) {
          right: 14px;
        }
        @media (min-width: 1024px) and (max-width: 1100px) {
          right: 2px;
        }
      }
    }
  }
  .dlm-widget-dataset-bc-label {
    font-size: 11px;
    background: $usana-blue-300;
    padding: 3px 6px;
    border-radius: 25px;
    color: $usana-white-000;
    text-transform: uppercase;
    text-align: center;
    min-width: 45px;
    max-width: 90px;
    position: relative;
    display: inline-table;
    .fontawesome-icons {
      font-size: 13px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 9px;
      padding: 3px 1px;
    }
  }
  canvas {
    margin-top: 35px;
  }
  .dlm-widget-chart-label {
    font-size: 11px;
    font-weight: 500;
  }
  .dlm-widget-chart-label-container.col-12 {
    padding-left: 53px;
  }

  .dlm-widget-chart-footer-title {
    color: $usana-blue-200;
    font-size: 12px;
    padding: 30px 0;
    display: table;
    width: 100%;
    .fontawesome-icons {
      font-size: 15px;
      position: relative;
      top: 0;
    }
    @include media-breakpoint-up(lg) {
      font-size: 13px;
    }
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .dlm-widget-chart-footer-title-position {
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        margin: 10px 0;
      }
    }
    .dropdown {
      @include media-breakpoint-up(md) {
        float: right;
      }
    }
  }
  .dlm-widget-dataset-bc-label {
    &.cycle1 {
      background: $usana-red-200;
    }
    &.cycle2 {
      background: $usana-green-200;
    }
    &.cycle3 {
      background: $usana-blue-200;
    }
    &.cycle4 {
      background: $usana-yellow-200;
    }
  }
  .open .btn-primary.dropdown-toggle {
    background: $usana-blue-400;
    z-index: 0;
  }
  .btn.btn-primary {
    margin-top: 10px;
  }
  .dropdown-menu {
    padding: 0;
    border: none;
    z-index: 1;
    li > a {
      background: $usana-white-000;
      color: $usana-grey-900;
      line-height: 2.5rem;
      &:hover,
      &:focus {
        background: $usana-grey-100;
        color: $usana-grey-900;
      }
    }
  }
}

.dlm-widget-box {
  &.volumewidget {
    .widget-spinner {
      display: block;
    }
  }
  &.fourweekcyclewidget {
    .widget-spinner {
      display: block;
    }
  }
  &.volumewidget,
  &.fourweekcyclewidget {
    .card-body {
      padding: 40px 16px;
      @include media-breakpoint-down(md) {
        padding: 40px 16px;
      }
    }
  }
}
.widget-loading-error {
  color: $usana-yellow-500;
  text-align: center;
  h4 {
    text-align: inherit;
    color: inherit;
  }
}

/************************/
/** Right Side Modal ****/
/************************/

.modal:not(.universal-modal).right {
  .modal-dialog {
    top: 0;
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    border: none;
  }

  &.fade .modal-dialog {
    right: -320px;
    -webkit-transition:
      opacity 0.3s linear,
      left 0.3s ease;
    -moz-transition:
      opacity 0.3s linear,
      left 0.3s ease;
    -o-transition:
      opacity 0.3s linear,
      left 0.3s ease;
    transition:
      opacity 0.3s linear,
      left 2s ease;
  }

  &.fade.in .modal-dialog {
    right: 0;
    -webkit-transition:
      opacity 0.3s linear,
      right 0.3s ease;
    -moz-transition:
      opacity 0.3s linear,
      right 0.3s ease;
    -o-transition:
      opacity 0.3s linear,
      right 0.3s ease;
    transition:
      opacity 0.3s linear,
      right 0.3s ease;
  }
}

/****************************/
/*** Notification Landing ***/
/****************************/
.notification-container {
  .notification-title-switch-box {
    padding: 1.25rem;
    text-align: center;
    @include media-breakpoint-up(sm) {
      padding: 0;
      text-align: left;
    }
    .notification-title-switch {
      display: inline-block;
      font-size: 13px;
      border-bottom: solid 2px $usana-grey-200;
      margin-right: 7px;
      padding-bottom: 2px;
      cursor: pointer;
      &.active {
        border-bottom: solid 2px $usana-blue-300;
        color: $usana-grey-600;
      }
    }
  }
  .dlm-widget-box {
    .card-header {
      .notification-title {
        font-weight: 500;
      }
      .fontawesome-icons {
        vertical-align: middle;
        &.notification-more-icon {
          cursor: pointer;

          &.dropdown-toggle::after {
            display: none;
          }
        }
      }
      .dropdown-menu {
        li {
          padding: 3px 10px;
          cursor: pointer;
        }

        li:hover {
          background: $usana-grey-100;
        }
      }
    }
    .card-body {
      padding-top: 0;
      padding-bottom: 0;
      .notification-title {
        color: $usana-blue-400;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 20px;
        margin-bottom: 2px;
      }
      .notification-subject {
        font-size: 14px;
        color: $usana-grey-900;
        line-height: 21px;
        font-weight: 400;
      }

      .notification-category-icon {
        padding: 0;
        width: auto;
        position: relative;
        top: 0;
      }
      .notification-row {
        background: $usana-white-000;
        border-bottom: solid 1px $usana-grey-100;
        padding: 0.8em 0;
        margin: 0 -0.9em;
        cursor: pointer;
        display: block;
        &.read {
          background: $usana-grey-100;
        }
      }

      .notification-time {
        font-size: 12px;
        color: $usana-grey-600;
        font-weight: 400;
        line-height: 18px;
        margin-top: 8px;
        display: block;
      }

      .notification-more-icon {
        cursor: pointer;
        &.dropdown-toggle::after {
          display: none;
        }
      }

      .archived-notification {
        margin-top: 10px;
      }

      .dropdown-menu {
        padding: 0;
        li {
          padding: 3px 10px;
          cursor: pointer;
        }
        li:hover {
          background: $usana-grey-100;
        }
      }
    }
  }
}

.modal-content {
  .notification-modal {
    .modal-header {
      padding: 16px;
      .notification-title {
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .fontawesome-icons {
        font-size: 20px;
        vertical-align: middle;
        color: $usana-grey-800;
        margin-right: -5px;
        cursor: pointer;
      }
    }
    .modal-body {
      padding: 16px;
      margin: 0;
      .notification-time {
        font-size: 12px;
        color: $usana-grey-100;
        display: block;
        .fontawesome-icons {
          font-size: 17px;
        }
        .col-2.pr-0 {
          max-width: 12%;
        }
      }
      .notification-body {
        line-height: 2.5rem;
        a {
          color: $usana-blue-300;
        }
      }
    }
  }
}
.ellipsis:after {
  font-family: $font-awesome-font-family;
  font-weight: 400;
  content: '\f141';
  font-style: normal;
  vertical-align: sub;
}

/*********************/
/*** Widget Canvas ***/
/*********************/
canvas {
  &.chart {
    @include media-breakpoint-up(lg) {
      max-height: 250px;
    }
    @include media-breakpoint-down(sm) {
      @media only screen and (max-width: 767px) and (orientation: landscape) {
        max-height: 205px;
      }
    }
    @media only screen and (min-width: 995px) and (max-width: 1366px) and (orientation: landscape) {
      max-height: 230px;
    }
  }
}

i.glyphicon.glyphicon-chevron-left:after {
  font-family: $font-awesome-font-family;
  font-weight: 600;
  content: '\f053';
  font-style: normal;
}

i.glyphicon.glyphicon-chevron-right:after {
  font-family: $font-awesome-font-family;
  font-weight: 600;
  content: '\f054';
  font-style: normal;
}
